export const publicationsList = [
    {
        id: 'NCCR_112024',
        title: 'NCI’s National Childhood Cancer Registry Data Platform: A New Data Sharing Resource to Analyze Cancer Surveillance and Real World Data',
        link: "https://d2xnga7irezzit.cloudfront.net/ccdi_hub_files/publications/NCI's+National+Childhood+Cancer+Registry+Data+Platform+-++A+New+Data+Sharing+Resource+to+Analyze+Cancer+Surveillance+and+Real+World+Data_AMIA+2024+Symposium+abstract.pdf",
        date: 'November 2024',
        summary: `<p>Childhood cancers are rare diseases with approximately 16,000 cases diagnosed annually in the US.¹ Childhood cancer research data are typically captured through multicenter clinical trials and available only to consortium members, or investigator-initiated research at a single institution. Cancer treatment is also delivered outside of clinical trials—real-world data generated as a by-product of clinical care are typically siloed in distinct institutional information systems (Electronic Health Records, Radiation Oncology, Laboratory, and Imaging) and are not available for general research use. While central cancer registries collect information on all reported cancers in their jurisdiction, their data have not been linked across registries and with real world data at the national level before. Finally, as patients and their families travel across the US for treatment, a single registry or institution may not hold all data representing a patient’s cancer journey. Data from all these sources typically reside at different institutions with different governance frameworks and have been encoded in a variety of standard and non-standard formats, making them unavailable for research use.</p>`,
        category: 'Abstract',
        tag: 'NCCR',
        conference: 'AMIA 2024 Annual Symposium',
    },
    {
        id: 'NCCR_092024',
        title: 'Automated Extraction and Provision of Electronic Health Record Data from Children with Cancer to National Childhood Cancer Center (NCCR) cancer registries',
        link: 'https://aacrjournals.org/cancerres/article/84/17_Supplement/B071/747181/Abstract-B071-Automated-extraction-and-provision',
        date: 'September 2024',
        summary: `<p>The National Childhood Cancer Registry (NCCR) is a central infrastructure that integrates childhood cancer data from central cancer registries and other sources to enhance access to and utilization of pediatric cancer data. The NCCR currently represents >70% of the United States pediatric population. Registries collect and publish cancer incidence and survival data for public health surveillance. Electronic health records (EHRs) are one data source. EHR data are currently obtained by manual collection at individual hospitals that then transfer data to population-based cancer registries. This study aimed to use automated data collection from hospital EHRs to broaden and standardize data reported to registries.</p>`,
        category: 'Abstract',
        tag: 'NCCR',
        conference: 'AACR 2024 Special Conference in Cancer Research: Advances in Pediatric Cancer Research',
    },
    {
        id: 'Ecosystem_062024',
        title: 'IMMUNOTAR - Integrative prioritization of cell surface targets for cancer immunotherapy',
        link: 'https://www.biorxiv.org/content/10.1101/2024.06.04.597422v1',
        date: 'June 2024',
        summary: `<p>Cancer remains a leading cause of mortality globally. Recent improvements in survival have been facilitated by the development of less toxic immunotherapies; however, identifying targets for immunotherapies remains a challenge in the field. To address this challenge, we developed IMMUNOTAR, a computational tool that systematically prioritizes and identifies candidate immunotherapeutic targets. IMMUNOTAR integrates user-provided RNA-sequencing or proteomics data with quantitative features extracted from publicly available databases based on predefined optimal immunotherapeutic target criteria and quantitatively prioritizes potential surface protein targets. We demonstrate the utility and flexibility of IMMUNOTAR using three distinct datasets, validating its effectiveness in identifying both known and new potential immunotherapeutic targets within the analyzed cancer phenotypes. Overall, IMMUNOTAR enables the compilation of data from multiple sources into a unified platform, allowing users to simultaneously evaluate surface proteins across diverse criteria. By streamlining target identification, IMMUNOTAR empowers researchers to efficiently allocate resources and accelerate immunotherapy development.</p>`,
        category: 'Secondary',
        tag: 'CCDI Ecosystem',
        type: 'Preprint',
    },
    {
        id: 'MCICOG_062024',
        title: 'The Molecular Characterization Initiative: A clinical, pathologic and genomic resource for the international pediatric brain tumor community',
        link: 'https://d2xnga7irezzit.cloudfront.net/ccdi_hub_files/publications/The+Molecular+Characterization+Initiative+-+A+clinical%2C+pathologic+and+genomic+resource+for+the+international+pediatric+brain+tumor+community_MCI+AANP+abstract.pdf',
        date: 'June 2024',
        summary: `<p>The Molecular Characterization Initiative (MCI) launched in 2022 under the NCI Childhood Cancer Data Initiative (CCDI) in partnership with the Children’s Oncology Group (COG). MCI represents a national strategy facilitating appropriate clinical and molecular characterization of childhood cancers while building an accessible data ecosystem. Since activation, 2400+ pediatric patients with CNS tumors have enrolled from 184 COG sites across the U.S. and globally. MCI provides free comprehensive genomic profiling for newly diagnosed CNS tumors, with results returned to physicians within 21 days. De-identified clinical and genomic data are deposited in the CCDI repository and may be accessed via the database of Genotypes and Phenotypes through a controlled data access request. Digital whole slide images corresponding to the extracted tissues are accessed through NCI Imaging Data Commons.</p>`,
        category: 'Abstract',
        tag: 'CCDI-MCI-COG',
        conference: '2024 AANP National Conference',
    },
    {
        id: 'Federation_052024',
        title: 'St. Jude Cloud’s Disease Ontology',
        link: 'https://university.stjude.cloud/docs/genomics-platform/about-our-data/disease-ontology/',
        date: 'May 2024',
        summary: `<p>Ontologies designed for disease classification have redefined our understanding of diseases by providing a hierarchical structure of complex biomedical data. In cancer research, they are critical for data sharing, integration, and collaboration among researchers. However, existing ontologies on pediatric cancer classification are limited. The World Health Organization (WHO) and OncoTree primarily focus on adult cancers while leaving gaps in many pediatric cancer subtypes driven by molecular etiology presented in recent scientific literature. To enable data sharing and integration of the whole-genome, whole-exome and RNA-seq data generated from 13,956 cases of pediatric cancer and long-term survivors on St. Jude Cloud, we recognized the significance of such gaps and initiated the development of a tailored disease ontology to address this issue.</p>`,
        category: 'Secondary',
        tag: 'CCDI Data Federation Resource',
        type: 'White Paper',
    },
    {
        id: 'NCCR_052024',
        title: 'Harmonization of Real-World Data to Common Data Elements for the National Childhood Cancer Registry',
        link: 'https://d2xnga7irezzit.cloudfront.net/ccdi_hub_files/publications/Harmonization+of+Real-World+Data+to+Common+Data+Elements+for+the+National+Childhood+Cancer+Registry_AMIA+CIC+Submission.pdf',
        date: 'May 2024',
        summary: `<p>While cancer in children and adolescents is rare, it is the primary cause of death from disease for those over the age of 1. The ability to conduct high quality population-level research studies that leverages all available data is crucial in rare disease such as childhood cancer. However, data captured by research consortiums, NCI-supported Cancer Centers, hospitals, and other sources are typically stranded in institutional data silos and unavailable for secondary data analyses within and across institutions. These RWD are frequently encoded using source-specific data models that are not harmonized or interoperable with data from other sources. Most treating institutions report case information to central cancer registries in the North American Association of Central Cancer Registries (NAACCR) standard as part of legally-mandated cancer surveillance. However, cancer registries typically do not receive detailed diagnostic, treatment, or long-term follow-up information that are valuable for understanding a patient’s long-term health outcomes during and after a cancer diagnosis. Augmenting existing cancer registry data with harmonized data from other sources, such as electronic health records (EHRs), genomic data, and institutional datasets, would greatly enhance the value of cancer registries for surveillance and research.</p>`,
        category: 'Abstract',
        tag: 'NCCR',
        conference: 'AMIA 2024 Clinical Informatics Conference',
    },
    {
        id: 'MCICOG_112023',
        title: 'Building a national molecular profiling strategy: A report from the NCI Childhood Cancer Data Initiative and the Childrens Oncology Group Soft Tissue Sarcoma Committee',
        link: 'https://d2xnga7irezzit.cloudfront.net/ccdi_hub_files/publications/Building+a+national+molecular+profiling+strategy_A+report+from+the+NCI+Childhood+Cancer+Data+Initiative+and+the+Childrens+Oncology+Group+Soft+Tissue+Sarcoma+Committee+CTOS+2023+v2.pdf',
        date: 'November 2023',
        summary: `<p>The Childhood Cancer Data Initiative (CCDI), is a 10-year, $50 million per year, US federal investment to advance pediatric cancer research through both new data generation and development of platforms for sharing data. The goal of the initiative is to learn from every child, adolescent, and young adult by bringing together the research, advocacy, and care communities in an ambitious effort to build a Data Ecosystem that enables rapid and systematic data collection, sharing, access, and analysis. A key component of the CCDI is the NCI’s Molecular Characterization Initiative (MCI). The MCI was initiated in 2022 and is run in collaboration with the Children’s Oncology Group (COG). The MCI offers at no cost to patients and institutions, clinically annotated, genomic characterization of tumors and germline samples to patients and their physician. These data are then deposited in an NCI repository for secondary use by the community. Here, we detail the MCI soft tissue sarcoma (STS) workflow and describe the first year of enrollment experience.</p>`,
        category: 'Abstract',
        tag: 'CCDI-MCI-COG',
        conference: 'Connective Tissue Oncology Society (CTOS) 2023 Annual meeting',
    },
    {
        id: 'Ecosystem_102023',
        title: 'Childhood cancer data initiative: Status report',
        link: 'https://pubmed.ncbi.nlm.nih.gov/37889049/',
        date: 'October 2023',
        summary: `<p>In March 2023, over 800 researchers, clinicians, patients, survivors, and advocates from the pediatric oncology community met to discuss the progress of the National Cancer Institute's Childhood Cancer Data Initiative. We present here the status of the initiative's efforts in building its data ecosystem and updates on key programs, especially the Molecular Characterization Initiative and the planned Coordinated National Initiative for Rare Cancers in Children and Young Adults. These activities aim to improve access to childhood cancer data, foster collaborations, facilitate integrative data analysis, and expand access to molecular characterization, ultimately leading to the development of innovative therapeutic approaches.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'Pediatric Blood & Cancer',
        pmid: '37889049',
    },
    {
        id: 'Ecosystem_multi_102023',
        title: 'A multi-institutional pediatric dataset of clinical radiology MRIs by the Children’s Brain Tumor Network',
        link: 'https://pubmed.ncbi.nlm.nih.gov/38106459',
        date: 'October 2023',
        summary: `<p>Pediatric brain and spinal cancers remain the leading cause of cancer-related death in children. Advancements in clinical decision-support in pediatric neuro-oncology utilizing the wealth of radiology imaging data collected through standard care, however, has significantly lagged other domains. Such data is ripe for use with predictive analytics such as artificial intelligence (AI) methods, which require large datasets. To address this unmet need, we provide a multi-institutional, large-scale pediatric dataset of 23,101 multi-parametric MRI exams acquired through routine care for 1,526 brain tumor patients, as part of the Children's Brain Tumor Network. This includes longitudinal MRIs across various cancer diagnoses, with associated patient-level clinical information, digital pathology slides, as well as tissue genotype and omics data. To facilitate downstream analysis, treatment-naïve images for 370 subjects were processed and released through the NCI Childhood Cancer Data Initiative via the Cancer Data Service. Through ongoing efforts to continuously build these imaging repositories, our aim is to accelerate discovery and translational AI models with real-world data, to ultimately empower precision medicine for children.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'ArXiv',
        pmid: '38106459',
    },
    {
        id: 'Ecosystem_092023',
        title: 'Radio-pathomic approaches in pediatric neuro-oncology: Opportunities and challenges',
        link: 'https://pubmed.ncbi.nlm.nih.gov/37841693/',
        date: 'September 2023',
        summary: `<p>With medical software platforms moving to cloud environments with scalable storage and computing, the translation of predictive artificial intelligence (AI) models to aid in clinical decision-making and facilitate personalized medicine for cancer patients is becoming a reality. Medical imaging, namely radiologic and histologic images, has immense analytical potential in neuro-oncology, and models utilizing integrated radiomic and pathomic data may yield a synergistic effect and provide a new modality for precision medicine. At the same time, the ability to harness multi-modal data is met with challenges in aggregating data across medical departments and institutions, as well as significant complexity in modeling the phenotypic and genotypic heterogeneity of pediatric brain tumors. In this paper, we review recent pathomic and integrated pathomic, radiomic, and genomic studies with clinical applications. We discuss current challenges limiting translational research on pediatric brain tumors and outline technical and analytical solutions. Overall, we propose that to empower the potential residing in radio-pathomics, systemic changes in cross-discipline data management and end-to-end software platforms to handle multi-modal data sets are needed, in addition to embracing modern AI-powered approaches. These changes can improve the performance of predictive models, and ultimately the ability to advance brain cancer treatments and patient outcomes through the development of such models.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'Neuro-Oncology Advances',
        pmid: '37841693',
    },
    {
        id: 'MCICOG_072023',
        title: 'Children’s Oncology Group’s 2023 blueprint for research: Epidemiology',
        link: 'https://pubmed.ncbi.nlm.nih.gov/37449937/',
        date: 'July 2023',
        summary: `<p>The Children's Oncology Group (COG) Epidemiology Committee has a primary focus on better understanding the etiologies of childhood cancers. Over the past 10 years, the committee has leveraged the Childhood Cancer Research Network, and now more recently Project:EveryChild (PEC), to conduct epidemiologic assessments of various childhood cancers, including osteosarcoma, neuroblastoma, germ cell tumors, Ewing sarcoma, rhabdomyosarcoma, and Langerhans cell histiocytosis. More recent studies have utilized questionnaire data collected as part of PEC to focus on specific characteristics and/or features, including the presence of congenital disorders and the availability of stored cord blood. Members of the COG Epidemiology Committee have also been involved in other large-scale National Institutes of Health efforts, including the Childhood Cancer Data Initiative and the Gabriella Miller Kids First Pediatric Research Program, which are improving our understanding of the factors associated with childhood cancer risk. Future plans will focus on addressing questions surrounding health disparities, utilizing novel biospecimens in COG epidemiology studies, exploring the role of environmental factors on the etiologies and outcomes of childhood cancer, collaborating with other COG committees to expand the role of epidemiology in childhood cancer research, and building new epidemiologic studies from the Molecular Characterization Initiative—all with the ultimate goal of developing novel prevention and intervention strategies for childhood cancer.</p>`,
        category: 'Secondary',
        tag: 'CCDI-MCI-COG',
        journal: 'Pediatric Blood & Cancer',
        pmid: '37449937',
    },
    {
        id: 'Ecosystem_062023_0101',
        title: 'The Childhood Cancer Data Initiative: Using the Power of Data to Learn From and Improve Outcomes for Every Child and Young Adult With Pediatric Cancer',
        link: 'https://pubmed.ncbi.nlm.nih.gov/37267580/',
        date: 'June 2023',
        summary: `<p>Data-driven basic, translational, and clinical research has resulted in improved outcomes for children, adolescents, and young adults (AYAs) with pediatric cancers. However, challenges in sharing data between institutions, particularly in research, prevent addressing substantial unmet needs in children and AYA patients diagnosed with certain pediatric cancers. Systematically collecting and sharing data from every child and AYA can enable greater understanding of pediatric cancers, improve survivorship, and accelerate development of new and more effective therapies. To accomplish this goal, the Childhood Cancer Data Initiative (CCDI) was launched in 2019 at the National Cancer Institute. CCDI is a collaborative community endeavor supported by a 10-year, $50-million (in US dollars) annual federal investment. CCDI aims to learn from every patient diagnosed with a pediatric cancer by designing and building a data ecosystem that facilitates data collection, sharing, and analysis for researchers, clinicians, and patients across the cancer community. For example, CCDI's Molecular Characterization Initiative provides comprehensive clinical molecular characterization for children and AYAs with newly diagnosed cancers. Through these efforts, the CCDI strives to provide clinical benefit to patients and improvements in diagnosis and care through data-focused research support and to build expandable, sustainable data resources and workflows to advance research well past the planned 10 years of the initiative. Importantly, if CCDI demonstrates the success of this model for pediatric cancers, similar approaches can be applied to adults, transforming both clinical research and treatment to improve outcomes for all patients with cancer.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'Journal of Clinical Oncology',
        pmid: '37267580',
    },
    {
        id: 'Ecosystem_062023_0102',
        title: 'Protocol for using single-cell sequencing to study the heterogeneity of NF1 nerve sheath tumors from clinical biospecimens',
        link: 'https://pubmed.ncbi.nlm.nih.gov/37167059/',
        date: 'June 2023',
        summary: `<p>Single-cell sequencing is a powerful technology to understand the heterogeneity of clinical biospecimens. Here, we present a protocol for obtaining single-cell suspension from neurofibromatosis type 1-associated nerve sheath tumors for transcriptomic profiling on the 10x platform. We describe steps for clinical sample collection, generation of single-cell suspension, and cell capture and sequencing. We then detail methods for integrative analysis, developmental Schwann cell trajectory building using bioinformatic tools, and comparative analysis. This protocol can be adapted for single-cell sequencing using mouse nerve tumors. For complete details on the use and execution of this protocol, please refer to Zhang et al. (2022).1.</p>`,
        category: 'Secondary',
        tag: 'CCDI Ecosystem',
        journal: 'STAR Protocols',
        pmid: '37167059',
    },
    {
        id: 'Ecosystem_062023_0201',
        title: 'Radiomics for characterization of the glioma immune microenvironment',
        link: 'https://pubmed.ncbi.nlm.nih.gov/37337080',
        date: 'June 2023',
        summary: `<p>Increasing evidence suggests that besides mutational and molecular alterations, the immune component of the tumor microenvironment also substantially impacts tumor behavior and complicates treatment response, particularly to immunotherapies. Although the standard method for characterizing tumor immune profile is through performing integrated genomic analysis on tissue biopsies, the dynamic change in the immune composition of the tumor microenvironment makes this approach not feasible, especially for brain tumors. Radiomics is a rapidly growing field that uses advanced imaging techniques and computational algorithms to extract numerous quantitative features from medical images. Recent advances in machine learning methods are facilitating biological validation of radiomic signatures and allowing them to "mine" for a variety of significant correlates, including genetic, immunologic, and histologic data. Radiomics has the potential to be used as a non-invasive approach to predict the presence and density of immune cells within the microenvironment, as well as to assess the expression of immune-related genes and pathways. This information can be essential for patient stratification, informing treatment decisions and predicting patients' response to immunotherapies. This is particularly important for tumors with difficult surgical access such as gliomas. In this review, we provide an overview of the glioma microenvironment, describe novel approaches for clustering patients based on their tumor immune profile, and discuss the latest progress on utilization of radiomics for immune profiling of glioma based on current literature.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'npj Precision Oncology',
        pmid: '37337080',
    },
    {
        id: 'Ecosystem_062023_0202',
        title: 'The children’s brain tumor network (CBTN) - Accelerating research in pediatric central nervous system tumors through collaboration and open science',
        link: 'https://pubmed.ncbi.nlm.nih.gov/36335802/',
        date: 'June 2023',
        summary: `<p>Pediatric brain tumors are the leading cause of cancer-related death in children in the United States and contribute a disproportionate number of potential years of life lost compared to adult cancers. Moreover, survivors frequently suffer long-term side effects, including secondary cancers. The Children's Brain Tumor Network (CBTN) is a multi-institutional international clinical research consortium created to advance therapeutic development through the collection and rapid distribution of biospecimens and data via open-science research platforms for real-time access and use by the global research community. The CBTN's 32 member institutions utilize a shared regulatory governance architecture at the Children's Hospital of Philadelphia to accelerate and maximize the use of biospecimens and data. As of August 2022, CBTN has enrolled over 4700 subjects, over 1500 parents, and collected over 65,000 biospecimen aliquots for research. Additionally, over 80 preclinical models have been developed from collected tumors. Multi-omic data for over 1000 tumors and germline material are currently available with data generation for > 5000 samples underway. To our knowledge, CBTN provides the largest open-access pediatric brain tumor multi-omic dataset annotated with longitudinal clinical and outcome data, imaging, associated biospecimens, child-parent genomic pedigrees, and in vivo and in vitro preclinical models. Empowered by NIH-supported platforms such as the Kids First Data Resource and the Childhood Cancer Data Initiative, the CBTN continues to expand the resources needed for scientists to accelerate translational impact for improved outcomes and quality of life for children with brain and spinal cord tumors.</p>`,
        category: 'Secondary',
        tag: 'CCDI Ecosystem',
        journal: 'Neoplasia',
        pmid: '36335802',
    },
    {
        id: 'Ecosystem_052023',
        title: 'OpenPBTA: The Open Pediatric Brain Tumor Atlas',
        link: 'https://pubmed.ncbi.nlm.nih.gov/37492101/',
        date: 'May 2023',
        summary: `<p>Pediatric brain and spinal cancers are collectively the leading disease-related cause of death in children; thus, we urgently need curative therapeutic strategies for these tumors. To accelerate such discoveries, the Children's Brain Tumor Network (CBTN) and Pacific Pediatric Neuro-Oncology Consortium (PNOC) created a systematic process for tumor biobanking, model generation, and sequencing with immediate access to harmonized data. We leverage these data to establish OpenPBTA, an open collaborative project with over 40 scalable analysis modules that genomically characterize 1,074 pediatric brain tumors. Transcriptomic classification reveals universal TP53 dysregulation in mismatch repair-deficient hypermutant high-grade gliomas and TP53 loss as a significant marker for poor overall survival in ependymomas and H3 K28-mutant diffuse midline gliomas. Already being actively applied to other pediatric cancers and PNOC molecular tumor board decision-making, OpenPBTA is an invaluable resource to the pediatric oncology community.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'Cell Genomics',
        pmid: '37492101',
    },
    {
        id: 'NCCR_052023',
        title: 'The Pediatric Proton and Photon Therapy Comparison Cohort: Study Design for a Multicenter Retrospective Cohort to Investigate Subsequent Cancers After Pediatric Radiation Therapy',
        link: 'https://pubmed.ncbi.nlm.nih.gov/38047226/',
        date: 'May 2023',
        summary: `<p>The physical properties of protons lower doses to surrounding normal tissues compared with photons, potentially reducing acute and long-term adverse effects, including subsequent cancers. The magnitude of benefit is uncertain, however, and currently based largely on modeling studies. Despite the paucity of directly comparative data, the number of proton centers and patients are expanding exponentially. Direct studies of the potential risks and benefits are needed in children, who have the highest risk of radiation-related subsequent cancers. The Pediatric Proton and Photon Therapy Comparison Cohort aims to meet this need.</p>`,
        category: 'Primary',
        tag: 'NCCR',
        journal: 'Advances in Radiation Oncology',
        pmid: '38047226',
    },
    {
        id: 'Ecosystem_112022',
        title: 'CIViCdb 2022: evolution of an open-access cancer variant interpretation knowledgebase',
        link: 'https://pubmed.ncbi.nlm.nih.gov/36373660/',
        date: 'November 2022',
        summary: `<p>CIViC (Clinical Interpretation of Variants in Cancer; civicdb.org) is a crowd-sourced, public domain knowledgebase composed of literature-derived evidence characterizing the clinical utility of cancer variants. As clinical sequencing becomes more prevalent in cancer management, the need for cancer variant interpretation has grown beyond the capability of any single institution. CIViC contains peer-reviewed, published literature curated and expertly-moderated into structured data units (Evidence Items) that can be accessed globally and in real time, reducing barriers to clinical variant knowledge sharing. We have extended CIViC's functionality to support emergent variant interpretation guidelines, increase interoperability with other variant resources, and promote widespread dissemination of structured curated data. To support the full breadth of variant interpretation from basic to translational, including integration of somatic and germline variant knowledge and inference of drug response, we have enabled curation of three new Evidence Types (Predisposing, Oncogenic and Functional). The growing CIViC knowledgebase has over 300 contributors and distributes clinically-relevant cancer variant data currently representing >3200 variants in >470 genes from >3100 publications.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'Nucleic Acids Research',
        pmid: '36373660',
    },
    {
        id: 'Ecosystem_042022',
        title: 'Large scale genotype‐ and phenotype‐driven machine learning in Von Hippel‐Lindau disease',
        link: 'https://pubmed.ncbi.nlm.nih.gov/35475554/',
        date: 'April 2022',
        summary: `<p>Von Hippel-Lindau (VHL) disease is a hereditary cancer syndrome where individuals are predisposed to tumor development in the brain, adrenal gland, kidney, and other organs. It is caused by pathogenic variants in the VHL tumor suppressor gene. Standardized disease information has been difficult to collect due to the rarity and diversity of VHL patients. Over 4100 unique articles published until October 2019 were screened for germline genotype-phenotype data. Patient data were translated into standardized descriptions using Human Genome Variation Society gene variant nomenclature and Human Phenotype Ontology terms and has been manually curated into an open-access knowledgebase called Clinical Interpretation of Variants in Cancer. In total, 634 unique VHL variants, 2882 patients, and 1991 families from 427 papers were captured. We identified relationship trends between phenotype and genotype data using classic statistical methods and spectral clustering unsupervised learning. Our analyses reveal earlier onset of pheochromocytoma/paraganglioma and retinal angiomas, phenotype co-occurrences and genotype-phenotype correlations including hotspots. It confirms existing VHL associations and can be used to identify new patterns and associations in VHL disease. Our database serves as an aggregate knowledge translation tool to facilitate sharing information about the pathogenicity of VHL variants.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'Human Mutation',
        pmid: '35475554',
    },
    {
        id: 'Ecosystem_032022',
        title: 'Standardized evidence-based approach for assessment of oncogenic and clinical significance of NTRK fusions',
        link: 'https://pubmed.ncbi.nlm.nih.gov/35366592/',
        date: 'March 2022',
        summary: `<p>Gene fusions involving the neurotrophic receptor tyrosine kinase genes NTRK1, NTRK2, and NTRK3, are well established oncogenic drivers in a broad range of pediatric and adult tumors. These fusions are also important actionable markers, predicting often dramatic response to FDA approved kinase inhibitors. Accurate interpretation of the clinical significance of NTRK fusions is a high priority for diagnostic laboratories, but remains challenging and time consuming given the rapid pace of new data accumulation, the diversity of fusion partners and tumor types, and heterogeneous and incomplete information in variant databases and knowledgebases. The ClinGen NTRK Fusions Somatic Cancer Variant Curation Expert Panel (SC-VCEP) was formed to systematically address these challenges and create an expert-curated resource to support clinicians, researchers, patients and their families in making accurate interpretations and informed treatment decisions for NTRK fusion-driven tumors. We describe a system for NTRK fusion interpretation (including compilation of key elements and annotations) developed by the NTRK fusions SC-VCEP. We illustrate this stepwise process on examples of LMNA::NTRK1 and KANK1::NTRK2 fusions. Finally, we provide detailed analysis of current representation of NTRK fusions in public fusion databases and the CIViC knowledgebase, performed by the NTRK fusions SC-VCEP to determine existing gaps and prioritize future curation activities.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'Cancer Genetics',
        pmid: '35366592',
    },
    {
        id: 'Ecosystem_082021',
        title: 'Cancer Informatics for Cancer Centers: Scientific Drivers for Informatics, Data Science, and Care in Pediatric, Adolescent, and Young Adult Cancer',
        link: 'https://pubmed.ncbi.nlm.nih.gov/34428097/',
        date: 'August 2021',
        summary: `<p>Cancer Informatics for Cancer Centers (CI4CC) is a grassroots, nonprofit 501c3 organization intended to provide a focused national forum for engagement of senior cancer informatics leaders, primarily aimed at academic cancer centers anywhere in the world but with a special emphasis on the 70 National Cancer Institute–funded cancer centers. This consortium has regularly held topic-focused biannual face-to-face symposiums. These meetings are a place to review cancer informatics and data science priorities and initiatives, providing a forum for discussion of the strategic and pragmatic issues that we faced at our respective institutions and cancer centers. Here, we provide meeting highlights from the latest CI4CC Symposium, which was delayed from its original April 2020 schedule because of the COVID-19 pandemic and held virtually over three days (September 24, October 1, and October 8) in the fall of 2020. In addition to the content presented, we found that holding this event virtually once a week for 6 hours was a great way to keep the kind of deep engagement that a face-to-face meeting engenders. This is the second such publication of CI4CC Symposium highlights, the first covering the meeting that took place in Napa, California, from October 14-16, 2019. We conclude with some thoughts about using data science to learn from every child with cancer, focusing on emerging activities of the National Cancer Institute's Childhood Cancer Data Initiative.</p>`,
        category: 'Primary',
        tag: 'CCDI Ecosystem',
        journal: 'JCO Clinical Cancer Informatics',
        pmid: '34428097',
    },
]